import clsx from 'clsx';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC } from 'react';

import './RadioInput.css';

type RadioInputProps = {
  formik: ReturnType<typeof useFormik>;
  name: string;
  label: string;
  value: string;
  showError?: boolean;
  className?: string;
};

const RadioInput: FC<RadioInputProps> = ({
  formik,
  name,
  label,
  value,
  showError = true,
  className,
}) => {
  return (
    <>
      <label
        htmlFor={`${name}-${value}`}
        className={clsx(
          'RadioInput-wrapper flex items-center text-sm text-white mt-2',
          className
        )}
      >
        <input
          id={`${name}-${value}`}
          type="radio"
          {...formik.getFieldProps({ name, type: 'radio', value })}
          className="mr-2 rounded-sm ring-1 ring-white text-transparent bg-transparent"
        />
        {label}
      </label>
      {showError && formik.touched[name] && formik.errors[name] ? (
        <div className="text-error">{formik.errors[name]}</div>
      ) : null}
    </>
  );
};

export default RadioInput;
