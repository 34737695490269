import * as _mapboxGl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
// @ts-ignore
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';

export * from './geocoder';

(_mapboxGl as any).workerClass = MapboxWorker;
(_mapboxGl as any).accessToken = process.env.MAPBOX_ACCESS_TOKEN;
export const mapboxGl = _mapboxGl;
export type MapboxGlMap = _mapboxGl.Map;
export type MapboxGlMarker = _mapboxGl.Marker;

export const DEFAULT_MAP_LOCATION = {
  lng: 13.67,
  lat: 47.44,
  zoom: 1.9,
};
