import clsx from 'clsx';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, forwardRef, InputHTMLAttributes, ReactNode } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { formatDate } from '@lib/format';

import './DatePicker.css';

type DateRangeCustomInputProps = {
  formik: ReturnType<typeof useFormik>;
  startName: string;
  endName: string;
  placeholder?: string;

  startPiece?: ReactNode;
};

export const DateRangeCustomInput = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement> & DateRangeCustomInputProps
>(
  (
    {
      onClick,
      onChange,
      startName,
      endName,
      formik,
      startPiece,
      placeholder,
      className,
    },
    ref
  ) => (
    <div className={'flex items-center'}>
      {startPiece && (
        <div className="text-white opacity-60 mx-4">{startPiece}</div>
      )}
      <input
        id={`${startName}-${endName}`}
        ref={ref}
        className={clsx(className, 'block w-full')}
        type="text"
        onClick={onClick}
        onChange={onChange}
        readOnly={!onChange ? true : undefined}
        autoComplete="off"
        value={
          formik.values[startName] && formik.values[endName]
            ? `${formatDate(formik.values[startName])} - ${formatDate(
                formik.values[endName]
              )}`
            : formik.values[startName]
            ? formatDate(formik.values[startName])
            : ''
        }
        placeholder={placeholder}
      />
    </div>
  )
);

export type DateIntervalInputProps = {
  className?: string;
  innerClassName?: string;

  formik: ReturnType<typeof useFormik>;
  startName: string;
  endName: string;

  label?: string;
  placeholder?: string;
  disabled?: boolean;
  showError?: boolean;

  startPiece?: ReactNode;
};

const DateIntervalInput: FC<DateIntervalInputProps> = ({
  className,
  innerClassName,

  formik,
  startName,
  endName,
  label,
  placeholder,
  disabled,
  showError = true,

  startPiece,
}) => {
  return (
    <div
      className={clsx(
        className,
        'DateIntervalInput-wrapper',
        disabled && 'opacity-50'
      )}
    >
      {label && (
        <label
          htmlFor={`${startName}-${endName}`}
          className="block text-sm mb-2"
        >
          {label}
        </label>
      )}
      <div
        className={clsx(
          innerClassName,
          `DateIntervalInput-input flex w-full mt-1 text-sm items-center           
          rounded-sm border-3/2 border-interaction-disabled
          shadow-sm 
          bg-background-dark`,
          disabled ? '' : 'hover:bg-background-avg'
        )}
      >
        <DatePicker
          selectsRange
          customInput={
            <DateRangeCustomInput
              formik={formik}
              startName={startName}
              endName={endName}
              startPiece={startPiece}
              placeholder={placeholder}
            />
          }
          startDate={formik.values[startName]}
          endDate={formik.values[endName]}
          onChange={([startDate, endDate]: [Date, Date]) => {
            formik.setFieldValue(startName, startDate);
            formik.setFieldValue(endName, endDate);
          }}
          shouldCloseOnSelect={false}
          placeholderText={placeholder}
          disabled={disabled}
        />
      </div>

      {showError && formik.touched[startName] && formik.errors[startName] ? (
        <div className="text-error">{formik.errors[startName]}</div>
      ) : null}

      {showError && formik.touched[endName] && formik.errors[endName] ? (
        <div className="text-error">{formik.errors[endName]}</div>
      ) : null}
    </div>
  );
};

export default DateIntervalInput;

export const DateStartPiece: FC = () => (
  <svg
    className="h-6 text-interaction-enabled cursor-pointer"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
      clipRule="evenodd"
    />
  </svg>
);
