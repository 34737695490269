import clsx from 'clsx';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC } from 'react';

import './CheckboxInput.css';

type CheckboxInputProps = {
  formik: ReturnType<typeof useFormik>;
  name: string;
  label: string;
  value?: string;
  showError?: boolean;
  className?: string;
  disabled?: boolean;
};

const CheckboxInput: FC<CheckboxInputProps> = ({
  formik,
  name,
  label,
  value,
  showError = true,
  className,
  disabled,
}) => {
  return (
    <>
      <label
        htmlFor={`${name}-${value}`}
        className={clsx(
          'CheckboxInput-wrapper flex items-center text-sm text-white mt-2',
          'cursor-pointer',
          className
        )}
      >
        <input
          id={`${name}-${value}`}
          type="checkbox"
          {...formik.getFieldProps({ name, type: 'checkbox', value })}
          className="mr-2 rounded-sm ring-1 ring-white text-transparent bg-transparent"
          disabled={disabled}
        />
        {label}
      </label>
      {showError && formik.touched[name] && formik.errors[name] ? (
        <div className="text-error">{formik.errors[name]}</div>
      ) : null}
    </>
  );
};

export default CheckboxInput;
