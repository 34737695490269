import { FormikControl, useModal } from '@lib';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, MutableRefObject } from 'react';
import DatePicker from 'react-datepicker';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';

import Button from '@components/Button';
import { DateRangeCustomInput, DateStartPiece } from '@components/form';

import './DeliveryWindowFilter.css';
import DropDownFilter from './DropDownFilter';

type DeliveryWindowFilterProps = {
  formik: FormikControl;
  innerFormikRef?: MutableRefObject<FormikControl>;
  label?: string;
  nameStart: string;
  nameEnd: string;
};

const DeliveryWindowFilter: FC<DeliveryWindowFilterProps> = ({
  formik,
  innerFormikRef = { current: undefined },
  label = 'Delivery Window',
  nameStart,
  nameEnd,
}) => {
  const dropDownControl = useModal();
  const startActive = !!formik.values[nameStart];
  const endActive = !!formik.values[nameEnd];

  const tempFormik = useFormik({
    initialValues: {
      start: (formik.values[nameStart] || undefined) as Date,
      end: (formik.values[nameEnd] || undefined) as Date,
    },
    onSubmit: (values) => {
      formik.setFieldValue(nameStart, values.start);
      formik.setFieldValue(nameEnd, values.end);
      dropDownControl.close();
    },
    onReset: () => {
      formik.setFieldValue(nameStart, undefined);
      formik.setFieldValue(nameEnd, undefined);
      dropDownControl.close();
    },
  });
  useUpdateRefIfShallowNew(innerFormikRef, tempFormik);

  return (
    <DropDownFilter
      label={label}
      active={startActive || endActive}
      dropDownClassName="w-96"
      dropDownControl={dropDownControl}
    >
      <form
        onSubmit={tempFormik.handleSubmit}
        onReset={tempFormik.handleReset}
        className="DeliveryWindowFilter-wrapper mb-0 "
      >
        <div className="flex flex-col justify-center">
          <div className="rounded-sm border-3/2 border-interaction-disabled">
            <DateRangeCustomInput
              className="bg-transparent border-0"
              formik={tempFormik}
              startName={'start'}
              endName={'end'}
              startPiece={<DateStartPiece />}
            />
          </div>
          <DatePicker
            className="m-auto"
            selectsRange
            inline
            startDate={tempFormik.values.start}
            endDate={tempFormik.values.end}
            onChange={([startDate, endDate]: [Date, Date]) => {
              tempFormik.setFieldValue('start', startDate);
              tempFormik.setFieldValue('end', endDate);
            }}
          />
        </div>
        <div className="hidden lg:flex flex-row space-x-14">
          <Button type="reset">Clear</Button>
          <Button type="submit" variant="primary">
            Apply
          </Button>
        </div>
      </form>
    </DropDownFilter>
  );
};

export default DeliveryWindowFilter;
