import { NOOP, useCategories, useIsMobile } from '@lib';
import clsx from 'clsx';
import { EventData, GeoJSONSource, MapLayerEventType } from 'mapbox-gl';
import * as React from 'react';
import { FC, useContext, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useInView } from 'react-intersection-observer';
import { useUpdateRefIfShallowNew } from 'use-query-params/lib/helpers';

import {
  Category,
  Shipping,
  Trade,
  Trade_Type,
} from '@redsleeve/oilynx-domain';

import {
  formatCurrency,
  formatDate,
  formatNumber,
  formatPriceBenchmark,
} from '@lib/format';
import { DEFAULT_MAP_LOCATION, mapboxGl, MapboxGlMap } from '@lib/mapbox';

import { TradesAndShippingsContext } from '../containers/TradesAndShippingsContext';
import buyIcon from '../resources/buyIcon.png';
import sellIcon from '../resources/sellIcon.png';
import shippingIcon from '../resources/shippingIcon5.png';

type TradePopupProps = {
  trade: Trade;
  category: Category;
};
const TradePopup: FC<TradePopupProps> = ({ trade, category }) => {
  const attrRowClass =
    'font-body text-xs flex flex-row items-center my-1 whitespace-nowrap';
  return (
    <div
      onTouchStart={() => {
        window.location = `/#${trade.id}` as any;
      }}
      onTouchEnd={(e) => {
        e.preventDefault();
      }}
      className="TradePopup-wrapper flex flex-row bg-background-light h-full no-underline"
    >
      <div className="px-4 py-3">
        <div className="font-bold font-emp text-base whitespace-nowrap">
          {trade.type === Trade_Type.Buy ? 'Buying' : 'Selling'} {category.name}
        </div>
        <div className={attrRowClass}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 text-interaction-light mr-2"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z"
              clipRule="evenodd"
            />
          </svg>
          {trade.priceIndication
            ? `${formatCurrency(trade.priceIndication, trade.currency)} /${
                trade.quantityUnit
              }`
            : formatPriceBenchmark(trade)}
        </div>
        <div className={attrRowClass}>
          <svg
            className="h-6 text-interaction-light mr-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
              clipRule="evenodd"
            />
          </svg>
          {formatDate(trade.deliveryWindowStart)}
          {' - '}
          {formatDate(trade.deliveryWindowEnd)}
        </div>
      </div>
      <div className="flex bg-interaction-enabled items-center justify-center w-6 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 text-white"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clipRule="evenodd"
          />
        </svg>
      </div>
    </div>
  );
};

const CapacityIcon = (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 text-interaction-light mr-2"
    // viewBox="0 0 512 512"
    viewBox="-70 -70 582 582"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill="currentColor"
      stroke="none"
    >
      <path d="M197 5106 c-90 -33 -163 -108 -187 -196 -6 -21 -10 -275 -10 -641 0 -690 -1 -676 76 -759 62 -66 122 -93 204 -93 88 0 157 30 215 95 69 77 75 110 75 392 l0 241 483 -482 c324 -323 497 -489 527 -504 64 -34 186 -34 250 -1 61 32 97 68 128 127 37 69 38 188 3 255 -15 30 -181 203 -504 528 l-482 482 241 0 c282 0 315 6 392 75 65 58 95 127 95 215 0 82 -27 142 -93 204 -83 78 -68 76 -764 76 -488 -1 -619 -4 -649 -14z" />
      <path d="M3602 5101 c-64 -22 -132 -85 -163 -150 -51 -105 -19 -243 73 -326 77 -69 110 -75 392 -75 l241 0 -482 -482 c-331 -333 -488 -497 -503 -527 -69 -137 -11 -311 126 -381 68 -35 188 -35 254 -1 30 15 203 181 528 504 l482 482 0 -241 c0 -282 6 -315 75 -392 58 -65 127 -95 215 -95 82 0 142 27 204 93 78 84 77 68 74 781 l-3 624 -30 53 c-32 59 -66 91 -129 125 -41 22 -43 22 -671 24 -592 3 -633 2 -683 -16z" />
      <path d="M1630 1981 c-69 -22 -130 -78 -577 -524 l-483 -482 0 241 c0 282 -6 315 -75 392 -58 65 -127 95 -215 95 -82 0 -142 -27 -204 -93 -78 -84 -77 -68 -74 -781 l3 -624 30 -53 c32 -59 66 -91 129 -125 41 -22 44 -22 665 -25 713 -3 697 -4 781 74 65 61 93 121 93 204 1 87 -30 156 -95 215 -77 69 -110 75 -392 75 l-241 0 482 482 c323 325 489 498 504 528 34 64 34 186 1 250 -52 99 -132 151 -241 156 -36 2 -77 0 -91 -5z" />
      <path d="M3310 1973 c-69 -25 -145 -107 -166 -178 -21 -73 -15 -155 16 -216 15 -30 172 -194 503 -527 l482 -482 -241 0 c-282 0 -315 -6 -392 -75 -65 -58 -95 -127 -95 -215 0 -82 27 -142 93 -204 84 -78 68 -77 781 -74 621 3 624 3 665 25 63 34 97 66 129 125 l30 53 3 624 c3 713 4 697 -74 781 -61 65 -122 93 -204 94 -87 0 -156 -30 -215 -96 -69 -77 -75 -110 -75 -392 l0 -241 -482 482 c-336 334 -497 488 -528 504 -59 29 -167 35 -230 12z" />
    </g>
  </svg>
);

type ShippingPopupProps = {
  shipping: Shipping;
};
const ShippingPopup: FC<ShippingPopupProps> = ({ shipping }) => {
  const attrRowClass =
    'font-body text-xs flex flex-row items-center my-1 whitespace-nowrap';
  return (
    <div
      onTouchStart={() => {
        window.location = `/shipping/#${shipping.id}` as any;
      }}
      onTouchEnd={(e) => {
        e.preventDefault();
      }}
      className="ShippingPopup-wrapper flex flex-row bg-background-light h-full no-underline"
    >
      <div className="px-4 py-3">
        <div className="font-bold font-emp text-base whitespace-nowrap">
          {shipping.name}
        </div>
        <div className={attrRowClass}>
          {CapacityIcon}
          {formatNumber(shipping.size)}
          {' cmb'}
        </div>
        <div className={attrRowClass}>
          <svg
            className="h-6 text-interaction-light mr-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
              clipRule="evenodd"
            />
          </svg>
          {formatDate(shipping.openWindowStart)}
          {!!shipping.openWindowEnd && (
            <>
              {' - '}
              {formatDate(shipping.openWindowEnd)}
            </>
          )}
        </div>
      </div>
      <div className="flex bg-interaction-enabled items-center justify-center w-6 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 text-white"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clipRule="evenodd"
          />
        </svg>
      </div>
    </div>
  );
};

export type OilMapProps = {
  mobileMode: 'map' | 'table';
};

const OilMap: FC<OilMapProps> = ({ mobileMode, children }) => {
  const {
    getTradesQuery,
    tradesFilterFormik,
    getShippingsQuery,
    shippingsFilterFormik,
  } = useContext(TradesAndShippingsContext);
  const isMobile = useIsMobile();

  const { getCategory } = useCategories();
  const getCategoryRef = useRef(getCategory);
  useUpdateRefIfShallowNew(getCategoryRef, getCategory);

  const mapContainer = useRef();
  const [mapWrapperContainer, isMapInView] = useInView();

  const mapRef = useRef<MapboxGlMap>();
  useEffect(() => {
    mapRef.current = new mapboxGl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/dark-v10',
      center: [DEFAULT_MAP_LOCATION.lng, DEFAULT_MAP_LOCATION.lat],
      zoom: DEFAULT_MAP_LOCATION.zoom,
    });

    mapRef.current.on('load', () => {
      const buyImg = new Image(20, 20);
      buyImg.onload = () => mapRef.current.addImage('buy-marker', buyImg);
      buyImg.src = buyIcon;

      const sellImg = new Image(20, 20);
      sellImg.onload = () => mapRef.current.addImage('sell-marker', sellImg);
      sellImg.src = sellIcon;

      const shippingImg = new Image(20, 20);
      shippingImg.onload = () =>
        mapRef.current.addImage('shipping-marker', shippingImg);
      shippingImg.src = shippingIcon;
    });

    const popup = new mapboxGl.Popup({
      closeButton: false,
      closeOnClick: false,
      offset: 15,
    });

    function onMouseEnter(
      e: (MapLayerEventType['mouseenter'] | MapLayerEventType['touchstart']) &
        EventData
    ) {
      mapRef.current.getCanvas().style.cursor = 'pointer';

      const coordinates = (e.features[0].geometry as any).coordinates.slice();
      let content: any;
      const contentPlaceholder = document.createElement('div');
      if (e.features[0].properties.trade) {
        const trade: Trade = JSON.parse(e.features[0].properties.trade);
        content = (
          <TradePopup
            trade={trade}
            category={
              getCategoryRef.current(trade.categoryId) ??
              ({ name: 'cannot load category' } as any)
            }
          />
        );
      } else {
        const shipping: Shipping = JSON.parse(
          e.features[0].properties.shipping
        );
        content = <ShippingPopup shipping={shipping} />;
      }

      ReactDOM.render(content, contentPlaceholder);

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }
      popup
        .setLngLat(coordinates)
        .setDOMContent(contentPlaceholder)
        .addTo(mapRef.current);
    }

    mapRef.current.on('touchstart', 'buyPoints', onMouseEnter);
    mapRef.current.on('touchstart', 'sellPoints', onMouseEnter);
    mapRef.current.on('touchstart', 'shippingPoints', onMouseEnter);

    mapRef.current.on('mouseenter', 'buyPoints', onMouseEnter);
    mapRef.current.on('mouseenter', 'sellPoints', onMouseEnter);
    mapRef.current.on('mouseenter', 'shippingPoints', onMouseEnter);

    function onMouseLeave() {
      requestAnimationFrame(() => {
        mapRef.current.getCanvas().style.cursor = '';
        popup.remove();
      });
    }

    mapRef.current.on('mouseleave', 'buyPoints', onMouseLeave);
    mapRef.current.on('mouseleave', 'sellPoints', onMouseLeave);
    mapRef.current.on('mouseleave', 'shippingPoints', onMouseLeave);

    function onClick(e: MapLayerEventType['click'] & EventData) {
      if (isMobile) return;

      if (e.features[0].properties.trade) {
        const trade: Trade = JSON.parse(e.features[0].properties.trade);
        window.location = `/#${trade.id}` as any;
      } else {
        const shipping: Shipping = JSON.parse(
          e.features[0].properties.shipping
        );
        window.location = `/shipping/#${shipping.id}` as any;
      }
    }

    mapRef.current.on('click', 'buyPoints', onClick);
    mapRef.current.on('click', 'sellPoints', onClick);
    mapRef.current.on('click', 'shippingPoints', onClick);

    return () => mapRef.current.remove();
  }, [mapRef, getCategoryRef]);

  function tradeToFeature(trade: Trade) {
    return {
      type: 'Feature' as 'Feature',
      geometry: {
        type: trade.deliveryPlace.location.type,
        coordinates: trade.deliveryPlace.location.coordinates,
      },
      properties: {
        trade,
      },
    };
  }

  function shippingToFeature(shipping: Shipping) {
    return {
      type: 'Feature' as 'Feature',
      geometry: {
        type: shipping.position.location.type,
        coordinates: shipping.position.location.coordinates,
      },
      properties: {
        shipping,
      },
    } as any;
  }

  useEffect(() => {
    requestAnimationFrame(() => {
      if (getTradesQuery.data) {
        const buyPointsSource = mapRef.current.getSource('buyPoints') as
          | GeoJSONSource
          | undefined;
        buyPointsSource?.setData({
          type: 'FeatureCollection',
          features: [
            ...getTradesQuery.data.getTrades
              .filter((trade) => trade.active)
              .filter((trade) => trade.type === Trade_Type.Buy)
              .map(tradeToFeature),
          ],
        });
        const sellPointsSource = mapRef.current.getSource('sellPoints') as
          | GeoJSONSource
          | undefined;
        sellPointsSource?.setData({
          type: 'FeatureCollection',
          features: [
            ...getTradesQuery.data.getTrades
              .filter((trade) => trade.active)
              .filter((trade) => trade.type === Trade_Type.Sell)
              .map(tradeToFeature),
          ],
        });
      }
    });
  }, [getTradesQuery.data]);

  useEffect(() => {
    requestAnimationFrame(() => {
      if (getShippingsQuery.data) {
        const shippingPointsSource = mapRef.current.getSource(
          'shippingPoints'
        ) as GeoJSONSource | undefined;
        shippingPointsSource?.setData({
          type: 'FeatureCollection',
          features: [
            ...getShippingsQuery.data.getShippings
              .filter((shipping) => shipping.active)
              // .filter((shipping) => shipping.type === Shipping_Type.Buy)
              .map(shippingToFeature),
          ],
        });
      }
    });
  }, [getShippingsQuery.data]);

  useEffect(() => {
    let cancelled = false;

    mapRef.current.once('load', () => {
      if (cancelled) return;
      try {
        mapRef.current.removeSource('buyPoints');
        mapRef.current.removeLayer('buyPoints');
        mapRef.current.removeSource('sellPoints');
        mapRef.current.removeLayer('sellPoints');
      } catch {
        NOOP();
      }
      if (getTradesQuery.data?.getTrades?.length) {
        mapRef.current.addSource('buyPoints', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [
              ...getTradesQuery.data.getTrades
                .filter((trade) => trade.active)
                .filter((trade) => trade.type === Trade_Type.Buy)
                .map(tradeToFeature),
            ],
          },
        });
        mapRef.current.addLayer({
          id: 'buyPoints',
          type: 'symbol',
          source: 'buyPoints',
          layout: {
            'icon-image': 'buy-marker',
            'icon-size': 0.75,
            'icon-allow-overlap': true,
          },
        });
        mapRef.current.addSource('sellPoints', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [
              ...getTradesQuery.data.getTrades
                .filter((trade) => trade.active)
                .filter((trade) => trade.type === Trade_Type.Sell)
                .map(tradeToFeature),
            ],
          },
        });
        mapRef.current.addLayer({
          id: 'sellPoints',
          type: 'symbol',
          source: 'sellPoints',
          layout: {
            'icon-image': 'sell-marker',
            'icon-size': 0.75,
            'icon-allow-overlap': true,
          },
        });
      }
    });
    return () => {
      cancelled = true;
    };
  }, [mapRef, getTradesQuery.data, tradesFilterFormik.values]);

  useEffect(() => {
    let cancelled = false;

    mapRef.current.once('load', () => {
      if (cancelled) return;
      try {
        mapRef.current.removeSource('shippingPoints');
        mapRef.current.removeLayer('shippingPoints');
      } catch {
        NOOP();
      }
      if (getShippingsQuery.data?.getShippings?.length) {
        mapRef.current.addSource('shippingPoints', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [
              ...getShippingsQuery.data.getShippings
                .filter((shipping) => shipping.active)
                .map(shippingToFeature),
            ],
          },
        });
        mapRef.current.addLayer({
          id: 'shippingPoints',
          type: 'symbol',
          source: 'shippingPoints',
          layout: {
            'icon-image': 'shipping-marker',
            'icon-size': 1,
            'icon-allow-overlap': true,
          },
        });
      }
    });
    return () => {
      cancelled = true;
    };
  }, [mapRef, getShippingsQuery.data, shippingsFilterFormik.values]);

  return (
    <>
      <div
        ref={mapWrapperContainer}
        data-tap-disabled="true"
        className={clsx(
          `HomePage-map ShippingPage-map bg-black lg:flex items-center justify-center relative -mx-4 lg:mx-0`,
          mobileMode === 'table' && 'hidden'
        )}
      >
        <div
          ref={mapContainer}
          className="fixed lg:absolute inset-y-0 w-full h-screen lg:h-screen-1/2"
        />

        <div
          className={clsx(
            'hidden lg:flex flex-row space-x-4 top-2',
            isMapInView ? 'absolute' : 'fixed z-10'
          )}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default OilMap;
