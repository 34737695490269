import clsx from 'clsx';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, useMemo } from 'react';
import { getTrackBackground, Range } from 'react-range';

import { HistogramEntry } from '@redsleeve/oilynx-domain';

export type RangeInputProps = {
  className?: string;
  innerClassName?: string;

  formik: ReturnType<typeof useFormik>;
  startName: string;
  endName: string;
  histogram: HistogramEntry[];

  label?: string;
  disabled?: boolean;
  showError?: boolean;
};

const RangeInput: FC<RangeInputProps> = ({
  className,
  innerClassName,

  formik,
  startName,
  endName,
  label,
  disabled,
  showError = true,
  histogram,
}) => {
  const min = useMemo(
    () =>
      Math.floor(
        histogram.reduce((acc, it) => Math.min(acc, it.min), Infinity)
      ),
    [histogram]
  );
  const max = useMemo(
    () =>
      Math.max(
        Math.ceil(
          histogram.reduce((acc, it) => Math.max(acc, it.max), -Infinity)
        ),
        min + 1
      ),
    [histogram, min]
  );
  const values = useMemo(
    () => [
      Math.round(
        Math.min(
          Math.max(
            formik.values[startName] === '' ? 0 : formik.values[startName],
            min
          ),
          max
        )
      ),
      Math.round(
        Math.max(
          Math.min(
            formik.values[endName] === '' ? 0 : formik.values[endName],
            max
          ),
          min
        )
      ),
    ],
    [formik.values[startName], formik.values[endName]]
  );

  /**
   * this is for the visual representation only
   * it is not exact, but accurate enough
   * we do this because we need an evenly distributed histogram
   */
  const remappedHistogram = useMemo(() => {
    const numberOfColumns = 10;

    const newHistogram = new Array(numberOfColumns)
      .fill(null)
      .map((_, index) => ({
        min: ((max - min) / numberOfColumns) * index + min,
        max: ((max - min) / numberOfColumns) * (index + 1) + min,
        count: 0,
      }));

    histogram.forEach((entry) => {
      const newEntriesThatFit = newHistogram.filter(
        (newEntry) => entry.min < newEntry.max && entry.max > newEntry.min
      );
      newEntriesThatFit.forEach((newEntry) => {
        newEntry.count += entry.count / newEntriesThatFit.length;
      });
    });

    return newHistogram;
  }, [histogram]);
  const maxCount = useMemo(
    () => Math.max(...remappedHistogram.map((it) => it.count)),
    [remappedHistogram]
  );
  const space = 10 / remappedHistogram.length;

  return (
    <div
      className={clsx(
        className,
        'RangeInput-wrapper',
        disabled && 'opacity-50'
      )}
    >
      {label && (
        <label
          htmlFor={`${startName}-${endName}`}
          className="block text-sm mb-2"
        >
          {label}
        </label>
      )}
      <div
        className={clsx(
          innerClassName,
          `RangeInput-input flex flex-col w-full mt-1 text-sm `
        )}
      >
        <div className="w-full h-12 relative mb-2">
          {remappedHistogram.map((col, index, { length }) => {
            const left = (100 * (col.min - min)) / (max - min);
            const right = 100 - (100 * (col.max - min)) / (max - min);

            return (
              <div
                className="bg-interaction-disabled absolute bottom-0"
                key={`${col.min}-${col.max}`}
                style={{
                  height: `${Math.floor((col.count / maxCount) * 100)}%`,
                  left: `${index ? left + space : left}%`,
                  right: `${index === length - 1 ? right : right + space}%`,
                }}
              />
            );
          })}
        </div>

        <div className="block w-full h-5 py-1">
          <Range
            min={min}
            max={max}
            step={1}
            values={values}
            onChange={([start, end]) => {
              formik.setFieldValue(startName, start);
              formik.setFieldValue(endName, end);
            }}
            renderTrack={({ props, children }) => (
              <div
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
                style={props.style}
                className="w-full h-3 flex"
              >
                <div
                  ref={props.ref}
                  className="bg-white w-full h-1 self-center"
                  style={{
                    background: getTrackBackground({
                      values,
                      colors: ['#ffffff', '#5289FF', '#ffffff'],
                      min,
                      max,
                    }),
                  }}
                >
                  {children}
                </div>
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                {...props}
                style={props.style}
                className="rounded-full bg-interaction-enabled h-3 w-3"
              />
            )}
          />
        </div>
      </div>

      {showError && formik.touched[startName] && formik.errors[startName] ? (
        <div className="text-error">{formik.errors[startName]}</div>
      ) : null}

      {showError && formik.touched[endName] && formik.errors[endName] ? (
        <div className="text-error">{formik.errors[endName]}</div>
      ) : null}
    </div>
  );
};

export default RangeInput;
