import { FormikControl } from '@lib';
import * as React from 'react';
import { FC } from 'react';

import SelectAndTextInput from '@components/form/SelectAndTextInput';
import { SelectInputValue } from '@components/form/SelectInput';
import TextInput from '@components/form/TextInput';

import { countryValues } from '@data/countries';

type ContactInfoFieldsProps = { formik: FormikControl };

const ContactInfoFields: FC<ContactInfoFieldsProps> = ({ formik }) => {
  return (
    <>
      <div className="flex mt-6">
        <TextInput
          formik={formik}
          className="w-full lg:w-72"
          name="contactInfoName"
          label="Full Name"
        />
      </div>
      <div className="flex mt-6 flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-6">
        <TextInput
          formik={formik}
          className="w-full lg:w-72"
          name="contactInfoCompanyName"
          label="Company Name"
          placeholder="eg: Redsleeve SA"
        />
        <TextInput
          formik={formik}
          className="w-full lg:w-72"
          name="contactInfoCompanyAddress"
          label="Company Address"
          placeholder="eg: Route de Florissant 47BIS, Genève, Switzerland"
        />
      </div>
      <div className="flex mt-6 flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-6">
        <SelectAndTextInput
          formik={formik}
          className="w-full lg:w-72"
          label="Phone Number"
          select={{
            name: 'contactInfoCountry',
            values: countryValues,
            reactSelectProps: {
              menuPlacement: 'top',
              getOptionLabel: (countryValue: SelectInputValue) =>
                countryValue.value?.name ?? '',
            },
          }}
          text={{
            name: 'contactInfoPhoneNumber',
            type: 'text',
          }}
        />
        <TextInput
          formik={formik}
          className="w-full lg:w-72"
          name="contactInfoEmail"
          label="Email"
          type="email"
        />
      </div>
    </>
  );
};

export default ContactInfoFields;
