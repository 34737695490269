import clsx from 'clsx';
import * as React from 'react';
import { FC, useContext } from 'react';

import Button from '@components/Button';

import { TradesAndShippingsContext } from '../../containers/TradesAndShippingsContext';

type ClearFiltersProps = {};

const ClearFilters: FC<ClearFiltersProps> = () => {
  const { shippingsFilterFormik, tradesFilterFormik } = useContext(
    TradesAndShippingsContext
  );

  const active = shippingsFilterFormik.dirty || tradesFilterFormik.dirty;

  return (
    <Button
      variant="none"
      disabled={!active}
      className={clsx(
        'flex items-center max-w-38 rounded-full text-xs font-emp text-white',
        active
          ? 'ring-2 bg-background-avg ring-interaction-enabled font-bold'
          : 'bg-background-dark ring-interaction-light'
      )}
      onClick={() => {
        shippingsFilterFormik.resetForm();
        tradesFilterFormik.resetForm();
      }}
    >
      Clear All Filters
    </Button>
  );
};

export default ClearFilters;
