import { ModalControl, useIsMobile } from '@lib';
import clsx from 'clsx';
import * as React from 'react';
import { FC, useContext, useEffect } from 'react';

import Button from '@components/Button';
import { FiltersContext } from '@components/filter/FiltersContext';

interface DropDownFilterProps {
  label: string;
  active: boolean;
  dropDownClassName?: string;
  dropDownControl: ModalControl;
}

const DropDownFilter: FC<DropDownFilterProps> = ({
  label,
  active,
  dropDownClassName,
  dropDownControl,
  children,
}) => {
  const isMobile = useIsMobile();
  const { registerFilterControl, unregisterFilterControl, closeOtherFilters } =
    useContext(FiltersContext);

  useEffect(() => {
    registerFilterControl(dropDownControl);
    return () => {
      unregisterFilterControl(dropDownControl);
    };
  }, [dropDownControl]);

  if (isMobile) {
    return (
      <div>
        <h4 className="mb-8 font-emp font-bold text-xl">{label}</h4>
        {children}
      </div>
    );
  }

  return (
    <div className="relative inline-block text-left">
      {dropDownControl.isOpen && (
        <div
          className="fixed inset-0"
          aria-hidden="true"
          onClick={dropDownControl.close}
        >
          <div className="absolute inset-0 bg-almostTransparent" />
        </div>
      )}
      <div>
        <Button
          variant="none"
          className={clsx(
            'flex ring-2 items-center max-w-38 rounded-full text-xs font-emp text-white',
            active
              ? 'bg-background-avg ring-interaction-enabled font-bold'
              : 'bg-background-dark ring-interaction-light'
          )}
          onClick={() => {
            closeOtherFilters(dropDownControl);
            dropDownControl.toggle();
          }}
        >
          {label}
        </Button>
      </div>

      <div
        className={clsx(
          'origin-top-middle absolute right-0 mt-2 rounded-md shadow-lg bg-background-light ring-1 ring-black ring-opacity-5 focus:outline-none z-10',
          'p-6',
          dropDownClassName,
          !dropDownControl.isOpen && 'hidden'
        )}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabIndex={-1}
      >
        {children}
      </div>
    </div>
  );
};

export default DropDownFilter;
