import { ModalControl } from '@lib';
import * as React from 'react';
import { FC, useState } from 'react';

export const FiltersContext = React.createContext<{
  closeOtherFilters: (current: ModalControl) => void;
  registerFilterControl: (control: ModalControl) => void;
  unregisterFilterControl: (control: ModalControl) => void;
}>({
  closeOtherFilters: () => undefined,
  registerFilterControl: () => undefined,
  unregisterFilterControl: () => undefined,
});

export const FiltersContextProvider: FC = ({ children }) => {
  const [controls, setControls] = useState<ModalControl[]>([]);

  function registerFilterControl(control: ModalControl) {
    setControls((ctrls) => [...ctrls, control]);
  }

  function unregisterFilterControl(control: ModalControl) {
    setControls((ctrls) => ctrls.filter((it) => it !== control));
  }

  function closeOtherFilters(current: ModalControl) {
    controls.filter((it) => it !== current).forEach((it) => it.close());
  }

  return (
    <FiltersContext.Provider
      value={{
        registerFilterControl,
        unregisterFilterControl,
        closeOtherFilters,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};
