import clsx from 'clsx';
import { useFormik } from 'formik';
import { PointLike } from 'mapbox-gl';
import * as React from 'react';
import { FC, useEffect, useRef, useState } from 'react';

import { COUNTRY_MAP } from '@data/countries';

import {
  DEFAULT_MAP_LOCATION,
  mapboxGl,
  MapboxGlMap,
  MapboxGlMarker,
} from '@lib/mapbox';

export type MapInputProps = {
  className?: string;
  innerClassName?: string;

  formik: ReturnType<typeof useFormik>;
  name: string;
  label?: string;
  showError?: boolean;
};

const MapInput: FC<MapInputProps> = ({
  className,
  innerClassName,

  formik,
  name,
  label,
  showError = true,
}) => {
  const mapContainer = useRef();
  const mapRef = useRef<MapboxGlMap>();
  const markerRef = useRef<MapboxGlMarker>();
  const [lng, setLng] = useState(DEFAULT_MAP_LOCATION.lng);
  const [lat, setLat] = useState(DEFAULT_MAP_LOCATION.lat);
  const [zoom, setZoom] = useState(DEFAULT_MAP_LOCATION.zoom);

  useEffect(() => {
    mapRef.current = new mapboxGl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/dark-v10',
      center: [lng, lat],
      zoom: zoom,
    });

    mapRef.current.on('move', () => {
      setLng(mapRef.current.getCenter().lng);
      setLat(mapRef.current.getCenter().lat);
      setZoom(mapRef.current.getZoom());
    });

    mapRef.current.on('click', function (e) {
      const bbox: [PointLike, PointLike] = [
        [e.point.x - 5, e.point.y - 5],
        [e.point.x + 5, e.point.y + 5],
      ];
      const features = mapRef.current.queryRenderedFeatures(bbox, {
        layers: [
          'settlement-label',
          'country-label',
          'airport-label',
          'poi-label',
          'settlement-subdivision-label',
        ],
      });

      if (features.length) {
        const foundFeature = features[0];
        formik.setFieldValue(name, {
          label: `${
            foundFeature.properties.name_en || foundFeature.properties.name
          }${
            foundFeature.properties.class !== 'country'
              ? `, ${
                  COUNTRY_MAP.get(foundFeature.properties.iso_3166_1)?.name ??
                  foundFeature.properties.iso_3166_1
                }`
              : ''
          }`,
          location: foundFeature.geometry,
        });
      }
    });

    return () => mapRef.current.remove();
  }, [mapRef]);

  useEffect(() => {
    if (mapRef.current) {
      markerRef.current && markerRef.current.remove();

      if (formik.values[name]) {
        markerRef.current = new mapboxGl.Marker()
          .setLngLat(formik.values[name].location.coordinates)
          .addTo(mapRef.current);

        mapRef.current.flyTo({
          center: formik.values[name].location.coordinates,
          zoom: 7,
        });
      } else {
        mapRef.current.flyTo({
          center: [DEFAULT_MAP_LOCATION.lng, DEFAULT_MAP_LOCATION.lat],
          zoom: DEFAULT_MAP_LOCATION.zoom,
        });
      }
    }
  }, [mapRef.current, formik.values[name]]);

  return (
    <div className={clsx(className, 'MapInput-wrapper w-full')}>
      {label && <label className="block text-sm mb-2">{label}</label>}
      <div className={clsx(innerClassName, `MapInput-input`)}>
        <div ref={mapContainer} className={`block h-52 w-full`} />
      </div>

      {showError && formik.touched[name] && formik.errors[name] ? (
        <div className="text-error">{formik.errors[name]}</div>
      ) : null}
    </div>
  );
};

export default MapInput;
