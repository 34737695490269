import clsx from 'clsx';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, ReactNode, useEffect, useMemo, useRef } from 'react';

import { TradeLocation } from '@redsleeve/oilynx-domain';

import { MapboxGeocoder, mapboxGl } from '@lib/mapbox';

import './GeocoderInput.css';

export type GeocoderInputProps = {
  className?: string;
  innerClassName?: string;

  formik: ReturnType<typeof useFormik>;
  name: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  showError?: boolean;

  startPiece?: ReactNode;
};

const GeocoderInput: FC<GeocoderInputProps> = ({
  className,
  innerClassName,

  formik,
  name,
  label,
  placeholder,
  disabled,
  showError = true,

  startPiece,
}) => {
  const inputId = useMemo(
    () => `name-${(Math.random() * 1000).toFixed(0)}`,
    []
  );
  const wrapperRef = useRef<HTMLDivElement>();
  const geocoderRef = useRef<MapboxGeocoder>();
  const placeholderRef = useRef(placeholder);

  useEffect(() => {
    requestAnimationFrame(() => {
      geocoderRef.current = new MapboxGeocoder({
        accessToken: mapboxGl.accessToken,
        types: 'country,region,place,postcode,locality,neighborhood',
        placeholder: placeholderRef.current,
      });

      geocoderRef.current.addTo(`#${inputId}`);

      geocoderRef.current.on('result', function (e) {
        formik.setFieldValue(name, {
          label: e.result.place_name_en ?? e.result.place_name,
          location: e.result.geometry,
        } as TradeLocation);
      });

      geocoderRef.current.on('clear', function () {
        formik.setFieldValue(name, undefined);
      });
    });

    return () => {
      geocoderRef.current = undefined;
      wrapperRef.current && (wrapperRef.current.innerHTML = '');
    };
  }, [wrapperRef, geocoderRef, placeholderRef, formik.setFieldValue]);

  useEffect(() => {
    if (geocoderRef.current) {
      (geocoderRef.current as any)._inputEl.value =
        formik.values[name]?.label ?? '';
    }
  }, [geocoderRef.current, formik.values[name]]);

  return (
    <div
      className={clsx(
        className,
        'GeocoderInput-wrapper',
        disabled && 'opacity-50'
      )}
    >
      {label && (
        <label htmlFor={inputId} className="block text-sm mb-2">
          {label}
        </label>
      )}
      <div
        className={clsx(
          innerClassName,
          `GeocoderInput-input flex w-full mt-1 text-sm items-center           
          rounded-sm border-3/2 border-interaction-disabled
          shadow-sm 
          bg-background-dark`,
          disabled ? '' : 'hover:bg-background-avg'
        )}
      >
        {startPiece && (
          <div className="text-white opacity-60 ml-3">{startPiece}</div>
        )}
        <div id={inputId} ref={wrapperRef} className={`block w-full`} />
      </div>

      {showError && formik.touched[name] && formik.errors[name] ? (
        <div className="text-error">{formik.errors[name]}</div>
      ) : null}
    </div>
  );
};

export default GeocoderInput;

export const PinStartPiece: FC = () => (
  <svg
    className="h-6 text-interaction-enabled"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
      clipRule="evenodd"
    />
  </svg>
);
